<template>
    <el-select v-model="isTest" class="w-30 ml-3" @change="clientTestChange">
        <el-option value="" label="Alla" />
        <el-option value="false" label="Bolag" />
        <el-option value="true" label="Testbolag" />
    </el-select>
</template>
<script>
import queryString from "query-string";
export default {
    data() {
        return {
            isTest: "Bolag",
        };
    },

    created() {
        this.assignInitValues();
    },

    methods: {
        assignInitValues() {
            const { isTest } = queryString.parse(location.search);
            this.isTest = isTest || this.isTest;
        },

        clientTestChange() {
            const query = queryString.parse(window.location.search, { sort: false });
            query.isTest = this.isTest;
            this.$router.replace({ query }).catch(() => {});
            this.$emit("change");
        },
    },
};
</script>
